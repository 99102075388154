<template>
  <v-container>
    <step-buttons-therapy
      class="my-2"
      is-last-step
      @click-next="submit"
      @click-back="back"
    />
    <therapy-card title="Revisão do Diagnóstico">
      <div>
        <label-icon icon="person" label="Paciente" bold>
          {{ patient.name }}
        </label-icon>
        <v-divider class="my-5" />

        <pares-section />

        <tumoral-phenomena-section />

        <vascular-phenomena-section />

        <potential-spaces-section />

        <flow-types-section />

        <oligoelements-section />

        <chakras-section />

        <chromosomes-section />

        <complementary-therapies-section />

        <v-divider class="my-5" />
        <label-icon icon="mode_comment" label="Comentários" bold />

        <v-textarea
          v-model="therapistComments"
          class="mt-3 text-area-custom"
          label="Informações adicionais (Protocolos, técnicas, procedimentos realizadas antes da terapia, exames complementares, comentários, etc). Este campo não será mostrado ao paciente."
          outlined
          rows="3"
        />
        <v-textarea
          v-model="patientComments"
          label="Comentários gerais ao paciente"
          outlined
          rows="3"
        />
      </div>

      <!-- File Upload Section -->
      <v-divider class="my-4"></v-divider>
      <v-row class="mb-4">
        <v-col cols="12">
          <multiple-input-file
            v-model="additionalFiles"
            label="Arquivos anexados"
            input-accept="image/*,application/pdf"
          ></multiple-input-file>
        </v-col>
      </v-row>
    </therapy-card>
    <step-buttons-therapy
      class="my-5"
      is-last-step
      @click-next="submit"
      @click-back="back"
    />
  </v-container>
</template>

<script>
export default {
  components: {
    TumoralPhenomenaSection: () => import("./TumoralPhenomenaSection"),
    VascularPhenomenaSection: () => import("./VascularPhenomenaSection"),
    ParesSection: () => import("./ParesSection"),
    PotentialSpacesSection: () => import("./PotentialSpacesSection"),
    FlowTypesSection: () => import("./FlowTypesSection"),
    OligoelementsSection: () => import("./OligoelementsSection"),
    ChakrasSection: () => import("./ChakrasSection"),
    ChromosomesSection: () => import("./ChromosomesSection"),
    ComplementaryTherapiesSection: () =>
      import("./ComplementaryTherapiesSection"),
  },
  computed: {
    therapistComments: {
      get() {
        return this.$store.state.diagnosis.diagnose.therapistComments;
      },
      set(value) {
        this.$store.commit("diagnosis/setTherapistComments", value);
      },
    },
    patientComments: {
      get() {
        return this.$store.state.diagnosis.diagnose.patientComments;
      },
      set(value) {
        this.$store.commit("diagnosis/setPatientComments", value);
      },
    },
    patient() {
      return this.$store.getters["therapy/patient"];
    },
    additionalFiles: {
      get() {
        return this.$store.state.therapy.therapy.additionalFiles;
      },
      set(newValue) {
        this.$store.commit("therapy/setAdditionalFiles", newValue);
      },
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    async submit() {
      await this.$store.dispatch("therapy/finish");
      this.$router.push({ name: "therapys" });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
